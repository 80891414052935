<template>
  <div>
    <div>
      <trac-tab-window :sectionTitle="'Noona Dashboard'" :subLinks="subLinks" />
    </div>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
export default {
  data() {
    return {
      type: "myIntegration",
      isLoading: false,
      integrationViewModalCtrl: false,
      selectedStore: null,
      selectedItem: null,
      businessDetails: false,
      allowSetLoyalty: false,
      allowallIntegration: false,
      loyaltyPoints: "",
      defaultLoyalty: 0,
      integrations: null,
      subLinks: [
        {
          name: "Appointment Link",
          path: "/noona/appointments",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "appointment-link-button",
        },
        {
          name: "Transactions",
          path: "/noona-transactions",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-0 big-shadow -mr-1',
          dataAttribute: "transactions-button",
        },
      ],
    };
  },
  created() {
    DELETE_LOCAL_DB_DATA("selected-integration");
  },
};
</script>


<style lang="postcss" scoped>
</style>